import React, { useState } from 'react'
import ContactView from './contact-view'
import { makeRequest } from '../../helpers'

const ContactController = () => {
  const [successEmail, setSuccessEmail] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const submitEmail = async (data) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    const bodyFormData = new FormData()
    bodyFormData.append('your-name', `${data.firstName} ${data.lastName}`)
    bodyFormData.append('your-email', data.email)
    bodyFormData.append('your-subject', data.subject)
    bodyFormData.append('your-message', data.message)
    bodyFormData.append('your-phone', `+${data.prefix} ${data.phone}`)

    makeRequest({
      headers,
      urlString: 'https://reflections.wecreatelabs.com.hk/wp-json/contact-form-7/v1/contact-forms/205/feedback',
      method: 'POST',
      data: bodyFormData,
    }).then((resp) => {
      if (resp.status === 'mail_sent') {
        setSuccessEmail(true)
      }
      if (resp.status === 'validation_failed') {
        setErrorEmail(true)
      }
    })
  }

  const viewProps = {
    submitEmail,
    successEmail,
    errorEmail,
  }
  return (
    <ContactView {...viewProps}/>

  )
}

export default ContactController
