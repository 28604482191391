import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik'
import * as styles from './contact-style.module.scss'
import { useFooter } from '../../hooks/use-footer'
import MapLocation from '../../components/map/map'
import Seo from '../../components/Seo'
import { Navbar } from '../../components'

const ContactView = ({
  submitEmail,
  successEmail,
  errorEmail,

}) => {
  const { t } = useTranslation()
  const { footer } = useFooter()

  let loading = false;

  return (
    <div className={styles.contact__container}>
       <Seo title="Kamo - Contact" />
	   <Navbar
        title={t('booking.titleSection')}
        loading={loading}
        t={t}
      />
      <div className={styles.contact__title}>
        <h2>{t('contact.title')}</h2>
        <p>{t('contact.subtitle')}</p>
      </div>
      <div className={styles.contact__box}>
        <aside className={styles.contact__aside}>
          <div className={styles.contact__aside__input}>
            <span>{t('contact.info.location')}</span>
            <p>{_.get(footer, 'contacts.location')}</p>
          </div>
          <div className={styles.contact__aside__input}>
            <span>{t('contact.info.whatsapp')}</span>
            <p>{_.get(footer, 'contacts.whatsapp')}</p>
          </div>
          <div className={styles.contact__aside__input}>
            <span>{t('contact.info.phone')}</span>
            <p>{_.get(footer, 'contacts.phone')}</p>
          </div>
          <div className={styles.contact__aside__input}>
            <span>{t('contact.info.email')}</span>
            <p>{_.get(footer, 'contacts.email')}</p>
          </div>
        </aside>
        <div className={styles.contact__form__container}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phone: '',
              prefix: '',
              email: '',
              subject: '',
              message: '',

            }}
            validate={(values) => {
              const errors = {}
              if (!values.firstName) {
                errors.firstName = t('contact.form.validation')
              }
              if (!values.lastName) {
                errors.lastName = t('contact.form.validation')
              }
              if (!values.phone) {
                errors.phone = t('contact.form.validation')
              }
              if (!values.prefix) {
                errors.prefix = t('contact.form.validation')
              }
              if (!values.subject) {
                errors.subject = t('contact.form.validation')
              }

              if (!values.message) {
                errors.message = t('contact.form.validation')
              }

              if (!values.email) {
                errors.email = t('contact.form.validation')
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address'
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                submitEmail(values)
                setSubmitting(false)
              }, 400)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className={styles.contact__form__row}>
                  <div className={`${styles.contact__form__group} ${styles.form__group}`}>
                    <Field type="text" name="firstName" className={styles.form__field} placeholder={t('contact.form.firstName')} />
                    <label className={styles.form__label} htmlFor="fistName">{t('contact.form.firstName')}</label>
                    <ErrorMessage className={styles.contact__form__error} name="firstName" component="div" />
                  </div>
                  <div className={`${styles.contact__form__group} ${styles.form__group}`}>
                    <Field placeholder={t('contact.form.lastName')} className={styles.form__field} type="text" name="lastName" />
                    <label className={styles.form__label} htmlFor="lastName">{t('contact.form.lastName')}</label>
                    <ErrorMessage className={styles.contact__form__error} name="lastName" component="div" />
                  </div>
                </div>
                <div className={styles.contact__form__row}>
                  <div className={`${styles.contact__form__group} ${styles.form__group}`}>
                    <Field placeholder={t('contact.form.email')} className={styles.form__field} type="email" name="email" />
                    <label className={styles.form__label} htmlFor="email">{t('contact.form.email')}</label>
                    <ErrorMessage className={styles.contact__form__error} name="email" component="div" />
                  </div>
                  <div className={`${styles.contact__form__group} ${styles.form__group} ${styles.contact__form__group__double}`}>
                    <div>
                      <Field placeholder={t('contact.form.phone')} className={styles.form__field} type="phone" name="prefix" />
                      <label className={styles.form__label} htmlFor="prefix">{t('contact.form.prefix')}</label>
                      <ErrorMessage className={styles.contact__form__error} name="prefix" component="div" />
                    </div>
                    <div>
                      <Field placeholder={t('contact.form.phone')} className={styles.form__field} type="phone" name="phone" />
                      <label className={styles.form__label} htmlFor="phone">{t('contact.form.phone')}</label>
                      <ErrorMessage className={styles.contact__form__error} name="phone" component="div" />
                    </div>
                  </div>
                </div>
                <div className={styles.contact__form__row}>
                  <div className={`${styles.contact__form__group} ${styles.form__group}`}>
                    <Field placeholder={t('contact.form.subject')} className={styles.form__field} type="text" name="subject" />
                    <label className={styles.form__label} htmlFor="subject">{t('contact.form.subject')}</label>
                    <ErrorMessage className={styles.contact__form__error} name="subject" component="div" />
                  </div>
                </div>
                <div className={styles.contact__form__row}>
                  <div className={`${styles.contact__form__group} ${styles.form__group}`}>
                    <Field
                      placeholder={t('contact.form.message')}
                      className={styles.form__field}
                      style={{ resize: 'none' }}
                      type="textarea"
                      name="message"
                      rows="4"
                      component="textarea"
                    />
                    <label className={styles.form__label} htmlFor="message">{t('contact.form.message')}</label>
                    <ErrorMessage className={styles.contact__form__error} name="message" component="div" />
                  </div>
                </div>

                <button className={styles.contact__button}type="submit" disabled={isSubmitting}>
                  {t('contact.form.submit')}
                </button>
                <div className={styles.contact__message}>
                  {successEmail && <span className={styles.contact__message__success}>{t('contact.form.success')}</span>}
                  {errorEmail && <span className={styles.contact__message__error}>{t('contact.form.error')}</span>}
                </div>
              </Form>
            )}
          </Formik>
        </div>

      </div>
      <div style={{marginTop:'180px'}}></div>
      <MapLocation showCard />
    </div>

  )
}

export default ContactView
